import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import SaleDetail from '../../entities/sales/saledetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import Sale from '@/store/entities/sales/sale';

interface SaleDetailState extends ListState<SaleDetail>{
    editModel:SaleDetail;    
    sale: number;
    price_rule: number;
    customer: number;
    group: number;
    method: number;
    reduction: number;
    tax: any;
    price_rules: Array<number>;
}
class SaleDetailModule extends ListModule<SaleDetailState,any,SaleDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<SaleDetail>(),
        loading:false,
        editModel: new SaleDetail(),
        activeList: new Array<SaleDetail>(),
        sale: 0,
        customer: 0,
        group: 0,
        method: 0,
        reduction: 0,
        tax: null,
        price_rule: Util.abp.setting.get('DEFAULT_PRICE_RULE_SALE'),
        price_rules: new Array<number>(),
        path: 'sales'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<SaleDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: SaleDetailState, model: SaleDetail) {
            state.editModel = model;
        },
        init(state: SaleDetailState, model: Sale) {
            state.editModel = Object.assign({});
            state.price_rule = Util.abp.setting.get('DEFAULT_PRICE_RULE_SALE');
            state.reduction = 0;
            state.customer = 0;
            state.group = 0;
            state.method = 0;
            state.tax = null;

            if (model != undefined) {
                state.sale = model.id;
                state.list = model.details;
                
                state.list.forEach((item, index) => {
                    if (item.credit_node_detail_id != null && item.credit_node_detail_id > 0 && item.quantity_returned >= item.quantity)
                        item['class'] = 'removed_item';

                    if (item.batches != null) {
                        item.batches.forEach(ba => {
                            ba.warehouse_id = item.warehouse_id;
                        });
                    }

                });           

                if (model.details.length > 0)
                    state.price_rule = model.details[0].price_rule_id;
            }
        },
        add(state: SaleDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id && detail.product_attribute_id == product['product_attribute_id'];
            });

            if (exist.length > 0) {
                exist[0].quantity += product.quantity;
                exist[0].total = exist[0].quantity * exist[0].price;
                exist[0].total_wt = exist[0].quantity * exist[0].price_wt
                exist[0].taxes = parseFloat((exist[0].total_wt - exist[0].total).toFixed(6));
            } else {
                if (state.reduction > 0 && (product['discount'] == null || product['discount'] == 0)) {
                    product['discount_type'] = 'percent';
                    product['discount'] = state.reduction;
                }

                let detail = new SaleDetail();
                detail.sale_id = state.editModel.id;
                detail.product = product;
                detail.product_id = product.id;
                detail.product_attribute_id = product['product_attribute_id'];
                detail.warehouse_id = (product['warehouse_id'] != undefined ? product['warehouse_id'] : Util.abp.session.warehouseId);
                detail.unity_id = product.unity_id;
                detail.unity_sym = product.unity_symbol;
                detail.price_rule_id = product.price_rule_id;
                detail.special_price_rule_id = product['special_price_rule_id'];
                detail.tax = (state.tax != null ? state.tax : product.tax);
                detail.tax_id = (state.tax != null ? state.tax.id : product.tax_id);
                detail.tax_rate = (state.tax != null ? state.tax.rate : product.tax_rate);
                detail.exchange_rate = product['exchange_rate'] != undefined ? product['exchange_rate'] : 1;
                detail.code = product.code;
                detail.upc = product.upc;
                detail.product_name = Util.formatProdName(product);
                detail.version = product.version;
                detail.stock_physical = product.stock_physical;
                detail.stock_reserved = product.stock_reserved;
                detail.stock = product.stock;
                detail.total_returned = 0;
                detail.quantity = product.quantity;                
                detail.price_wt = parseFloat(String(product.price_wt));
                detail.price = Util.removeTaxes(detail.price_wt, detail.tax_rate);
                detail.discount_type = (product['discount_type'] != null ? product['discount_type'] : 'percent');
                detail.discount_manual = false;
                detail.discounts = (product['discount'] != null ? product['discount'] : 0);
                detail.total = parseFloat((detail.quantity * detail.price).toFixed(6));
                detail.total_wt = parseFloat((detail.quantity * detail.price_wt).toFixed(6));
                detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(3));
                detail.product_price = product['product_price'];
                detail.product_cost = product.product_cost;
                detail.service = product['service'];
                detail.gift = product['gift'];
                if (detail.gift) {
                    detail.discounts = 0;
                }
                Util.updateTotalDetails(detail);

                if (product['location'] != null) {
                    detail['location'] = product['location'];
                    detail.batches = [{ product_location_id: product['location'].id, warehouse_id: detail.warehouse_id }];
                }
		
                if (product['special_price_rule_id'] != null) {
                    detail.special_price_rule = {
                        id: product['special_price_rule_id'],
                        groups: product['groups'],
                        customers: product['customers'],
                        payment_methods: product['payment_methods'],
                        price_rules: product['price_rules'],
                        from_quantity: product['from_quantity'],
                        discount_type: product['discount_type'],
                        discount: product['discount'],
                    };
                }                

                state.list.push(detail);
            }
        },
        updateList(state: SaleDetailState, data: any) {
            state.list[data.index].warehouse_id = data.detail.warehouse_id;
            state.list[data.index].price_rule_id = data.detail.price_rule_id;
            state.list[data.index].tax_id = data.detail.tax_id;
            state.list[data.index].tax_rate = data.detail.tax_rate;
            state.list[data.index].quantity = data.detail.quantity;
            state.list[data.index].price = data.detail.price;
            state.list[data.index].price_wt = data.detail.price_wt;
            state.list[data.index].taxes = data.detail.taxes;
            state.list[data.index].total = data.detail.total;
            state.list[data.index].total_wt = data.detail.total_wt;
            state.list[data.index].discounts = data.detail.discounts;
            state.list[data.index].discount_type = data.detail.discount_type;
            state.list[data.index].discount_manual = data.detail.discount_manual;            
            state.list[data.index].gift = data.detail.gift;
        },
        delete(state: SaleDetailState, index: number) {
            state.list.splice(index, 1);
        },
        setPriceRule(state: SaleDetailState, data: any) {
            state.list.forEach(item => {
                if (item.product_id == data.product_id && item.product_attribute_id == data.product_attribute_id) {
                    item.product_price = data.product_price;
                    item.price_wt = data.price_wt;
                    item.price = data.price;
                    item.price_rule_id = data.price_rule_id;
                    Util.updateTotalDetails(item);
                }
            });
        },
        setTax(state: SaleDetailState, tax: any) {
            state.tax = tax;
            state.list.forEach(item => {
                item.tax_id = tax.id;
                item.tax_rate = tax.rate;
                item.tax = tax;
                Util.updateTotalDetails(item);
            });
        },
        setGlobalParams(state: SaleDetailState, data: any) {
            let changePrice = false;
            let check = false;
            if (data.price_rule_id != undefined) {
                changePrice = (data.price_rule_id != state.price_rule);
                state.price_rule = Util.abp.setting.get('DEFAULT_PRICE_RULE');
                if (data.price_rule_id != undefined && data.price_rule_id > 0)
                    state.price_rule = data.price_rule_id;
                check = true;
            }
            if (data.price_rules != undefined) 
                state.price_rules = data.price_rules;

            if (data.reduction != undefined) {
                state.reduction = data.reduction;
                check = true;
            }

            if (data.customer_id != undefined) {
                state.customer = data.customer_id;
                check = true;
            }
            if (data.group_id != undefined) {
                state.group = data.group_id;
                check = true;
            }
            if (data.payment_method_id != undefined) {
                state.method = data.payment_method_id;
                check = true;
            }
            
            if (check) {
                state.list.forEach(item => {
                    if (!item.discount_manual && !item.gift) {
                        if ((item.id == null || item.id == undefined)) {
                            item.discounts = 0;
                        }

                        if (item.special_price_rule != null) {
                            item.special_price_rule_id = null;
                            item.discounts = 0;

                            let rule1 = ((state.group > 0 && item.special_price_rule.groups != null && item.special_price_rule.groups.length > 0 && item.special_price_rule.groups.indexOf(state.group) >= 0) || item.special_price_rule.groups == null);
                            let rule2 = ((state.customer > 0 && item.special_price_rule.customers != null && item.special_price_rule.customers.length > 0 && item.special_price_rule.customers.indexOf(state.customer) >= 0) || item.special_price_rule.customers == null);
                            let rule3 = ((state.method > 0 && item.special_price_rule.payment_methods != null && item.special_price_rule.payment_methods.length > 0 && item.special_price_rule.payment_methods.indexOf(state.method) >= 0) || item.special_price_rule.payment_methods == null);
                            let rule4 = ((state.price_rule > 0 && item.special_price_rule.price_rules != null && item.special_price_rule.price_rules.length > 0 && item.special_price_rule.price_rules.indexOf(item.price_rule_id) >= 0) || item.special_price_rule.price_rules == null);
                            let rule5 = (item.quantity >= item.special_price_rule.from_quantity);

                            if (rule1 && rule2 && rule3 && rule4 && rule5) {
                                item.special_price_rule_id = item.special_price_rule.id;
                                item.discount_type = item.special_price_rule.discount_type;
                                item.discounts = item.special_price_rule.discount;
                                if (item.discount_type == 'amount') {
                                    item.discounts = item.special_price_rule.discount * item.quantity;
                                }
                            }

                            Util.updateTotalDetails(item);
                        } else if (state.reduction > 0) {
                            item.discount_type = 'percent';
                            item.discounts = state.reduction;
                            Util.updateTotalDetails(item);
                        }
                    }
                });
            }

            //revisar si cambio de tipo de precio
            if (changePrice) {
                state.list.forEach(item => {
                    if (item.price_rule_id != state.price_rule && item.product != null && item.product.prices != null) {
                        let rule = item.product.prices.filter((item: any) => {
                            return item.price_rule_id == state.price_rule;
                        })[0];

                        item.price_rule_id = state.price_rule;
                        item.price_wt = parseFloat(String(rule.price_wt));
                        item.price = Util.removeTaxes(item.price_wt, item.tax_rate);
                        item.product_price = parseFloat(String(rule.price_wt));
                        Util.updateTotalDetails(item);
                    }
                });
            }
        },
        updateStocks(state: SaleDetailState, products: any) {
            state.list.forEach(item => {
                let exist = products.filter((product: any) => {
                    return item.product_id === product.id && item.product_attribute_id == product['product_attribute_id'];
                });

                if (exist.length > 0) {
                    item.stock = exist[0].stock;
                }
            });
        },
    });
}
const saledetailModule=new SaleDetailModule();
export default saledetailModule;