import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Pack from '../../entities/catalogs/pack'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'
import Product from '@/store/entities/catalogs/product';
import PackGroup from '@/store/entities/catalogs/packgroup';

interface PackState extends ListState<Pack>{
    editModel:Pack;
    product:number;
    product_attribute: number;
    discount: number;
    groups: Array<any>;
}
class PackModule extends ListModule<PackState,any,Pack>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Pack>(),
        loading:false,
        product: 0,
        product_attribute: 0,
        discount: 0,
        groups: new Array<any>(),
        editModel:new Pack(),
        path: 'packs',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<PackState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/product-pack-items', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = reponse.data.result.length;
            context.state.list = reponse.data.result;
            return context.state.list;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: PackState, model: Pack) {
            state.editModel = Object.assign({});
            state.list = [];
            state.groups = [];
            state.totalCount = 0;
        },
        setProduct(state: PackState, id: number) {
            state.product = id;
        },
        setProductAttribute(state: PackState, id: number) {
            state.product_attribute = id;
        },
        add(state: PackState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                let valid = (detail.item_id == product.id);
                if (state.product != undefined && state.product > 0) {
                    valid = valid && (detail.product_id == state.product);
                }

                if (state.product_attribute != undefined && state.product_attribute > 0) {
                    valid = valid && (detail.product_attribute_id == state.product_attribute);
                }

                return valid             
            });

            if (exist.length > 0) {
                exist[0].quantity += product.quantity;
                exist[0].item_price = exist[0].item_unit_price * exist[0].quantity;
            } else {
                let field = Util.abp.setting.get('PACK_AMOUNT_ADD_MODE', 'cost');
                let price_wt = product[field];

                if (state.discount > 0)
                    price_wt = parseFloat((price_wt * (1 - (state.discount / 100))).toFixed(6));

                let detail = new Pack();
                detail.product_id = state.product;
                detail.product_attribute_id = state.product_attribute;
                detail.item_id = product.id;
                detail.item_attribute_id = product['product_attribute_id'];                
                detail.item_type_id = product.product_type_id;
                //detail.item_type_id = Util.abp.list.get('T_ITEM', 'HE').id;
                detail.code = product.code;
                detail.reference = product.reference;
                detail.item_name = product.name;
                detail.item_unity_id = product.unity_id;
                detail.currency_id = product.currency_id;
                detail.currency_sign = product['currency_sign'];                
                detail.item_unit_price = price_wt;
                detail.unity_sym = product.unity_symbol;                
                detail.quantity = (product['quantity'] != null ? product['quantity'] : 1);
                detail.weight = (product['weight'] != null ? product['weight'] : 0);
                detail.item_price = detail.item_unit_price * detail.quantity;
                detail.optional = false;
                
                state.list.push(detail);
                state.totalCount = state.list.length;
            }
        },
        delete(state: PackState, index: number) {
            state.list.splice(index, 1);
        },        
        setDiscount(state: PackState, amount: number) {
            state.discount = amount;
        },
        loadGroups(state: PackState, data: any) {
            if (Util.abp.setting.get('ALLOW_PACK_GROUP') == 1) {
                state.list.forEach(item => {
                    if (item.group != null) {
                        let items = state.groups.filter(g => { return g.id == item.group_id });
                        if (items.length == 0) {
                            let group = new PackGroup();
                            group.id = item.group.id;
                            group.nro = item.group.nro;
                            group.name = item.group.name;
                            group.items = [item.key];
                            state.groups.push(group);
                        } else {
                            if (!items[0].items.includes(item.key))
                                items[0].items.push(item.key);
                        }
                    }
                })
            }
        },
        addGroup(state: PackState, data: any) {
            let exist = state.groups.filter((detail: any) => {
                return detail.name == data.name;
            });

            if (exist.length == 0) {
                let detail = new PackGroup();
                detail.nro = state.groups.length + 1;
                detail.name = data.name;
                detail.items = data.items;
                state.groups.push(detail);
            }
        },
        saveGroups(state: PackState, data: any) {
            state.list.forEach(a => {
                a.group_nro = 0;
                a.group = null;
                state.groups.forEach(g => {
                    if (g.items.includes(a.key)) {
                        a.group_nro = g.nro;
                        a.group = g;
                    }
                });
            });

            state.list = state.list.sort((a, b) => {
                return a.group_nro - b.group_nro;
            });
        },
        deleteGroup(state: PackState, index: number) {
            state.groups.splice(index, 1);
        },
    });
}
const packModule=new PackModule();
export default packModule;