import Entity from '../entity'
import Tax from '../preferences/tax';
import MeasureUnit from '../catalogs/measureunit';
import Product from '../catalogs/product';

export default class SaleDetail extends Entity<number>{
    sale_id: number;
    product_id: number;
    product_attribute_id: number;
    warehouse_id: number;
    unity_id: number;
    unity_sym: string;
    price_rule_id: number;
    special_price_rule_id: number;
    tax_id: number;
    tax_rate: number;
    exchange_rate: number;
    code: string;
    upc: string;
    product_name: string;
    version: string;
    quantity: number;
    stock_physical: number;
    stock_reserved: number;
    stock: number;
    discount_type: string;
    price: number;
    price_wt: number;
    taxes: number;
    discounts: number;
    total: number;
    total_wt: number;
    product_price: number;
    product_cost: number;    
    gift: boolean;
    quantity_returned: number;
    credit_node_detail_id: number;
    service: boolean;

    total_returned: number;
    tax: Tax;
    unity: MeasureUnit;
    product: Product;
    special_price_rule: any;
    location: any;
    locations: Array<any>;
    cost_wt: number;
    discount_manual: boolean;

    batches: Array<any>;
}


